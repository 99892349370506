import React from "react";
import styles from '../pages_styles/Infraestrutura.module.css';

function Infraestrutura () {
    return (
        <div>
            <h1>Hello World!</h1>
        </div>
    )
}

export default Infraestrutura;