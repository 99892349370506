import React from "react";
import styles from '../pages_styles/GradeHoraria.module.css';

function GradeHoraria () {
    return (
        <div>
            <h1>Hello World!</h1>
        </div>
    )
}

export default GradeHoraria;