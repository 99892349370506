import React from "react";
import styles from '../pages_styles/NiveisEnsino.module.css';

function Niveis() {
    return (
        <div>
            <h1>Hello World</h1>
        </div>
    )
}

export default Niveis;